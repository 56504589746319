<template>
  <div v-bind="componentData">
    <slot
      v-for="element in list"
      :key="element.id"
      name="item"
      :element="element"
    />
  </div>
</template>

<script setup lang="ts">
import type { Database } from "~/types/database.types";
type Product = Database["public"]["Tables"]["products"]["Row"];

const props = defineProps({
  modelValue: {
    type: Array<Product>,
    required: false,
  },
  itemKey: {
    type: String,
    required: true,
  },
  componentData: {
    type: Object,
    default: () => {
      return {};
    },
  },
  list: {
    type: Array<Product>,
    required: false,
  },
});
const { modelValue, itemKey, componentData } = toRefs(props);
</script>
