<template>
  <div class="campaign-popup-outline">
    <div class="popup-body">
      <div class="popup-header">
        <button class="close-button" :disabled="submitted" @click="closePopup">
          X
        </button>
        <h3 class="popup-title">Free Crafty Printables</h3>
      </div>
      <input
        v-if="!submitted"
        v-model="emailAddress"
        class="email-field"
        placeholder="Enter your E-Mail"
        title="EMail"
        @keyup.enter="submitEmail"
      >
      <h4 v-else class="thank-you-text">Thank you!</h4>
      <button class="submit-button" :disabled="submitted" @click="submitEmail">
        Instant Download!
      </button>
    </div>
    <img
      class="template-image"
      height="920"
      width="945"
      style="width: 134px; height: 130px"
      alt="Templates And Instructions"
      src="~assets/email-template.svg"
    >
  </div>
</template>

<script setup lang="ts">
import { useGtag } from "vue-gtag-next";
import { useCampaignStore } from "~/store/campaign";

const { event } = useGtag();

// Store

const campaignStore = useCampaignStore();

// Data

const emailAddress = ref<string | null>(null);
const submitted = ref(false);

// Functions

function closePopup() {
  event("click_hide_mailable_popup");
  campaignStore.closeCampaign();
}

function submitEmail() {
  event("subscribe_mailable_popup");
  if (emailAddress.value === null) {
    return;
  }
  campaignStore.campaignSignUp({ email: emailAddress.value });
  submitted.value = true;
}
</script>

<style scoped>
.campaign-popup-outline {
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 450px;
  height: 130px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #d16934;
}
@media screen and (max-width: 600px) {
  .campaign-popup-outline {
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
  .popup-title {
    font-size: 4vw;
  }
}
.template-image {
  float: right;
  height: 100%;
  width: auto;
}
.popup-body {
  height: 100%;
  width: calc(100% - 140px);
  display: inline-block;
}
.popup-header {
  position: relative;
  left: 10px;
  top: 10px;
}
.popup-title {
  font-style: italic;
  margin-left: 20px;
  color: white;
  display: inline-block;
}
.email-field {
  margin: 15px 20px;
  width: calc(100% - 20px);
  border-radius: 3px;
  outline: none;
  border: none;
}
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
.close-button {
  width: 26px;
  height: 26px;
  color: white;
  float: left;
  border: 0;
  background: #7f3616 none;
  box-shadow: none;
  border-radius: 6px;
}
.close-button:focus {
  background: #7f3616 none;
  outline: none;
  border: none;
}
.close-button:hover {
  background: #7f3616 none;
  border: none;
  box-shadow: none;
  outline: none;
}
.submit-button {
  height: 30px;
  margin: auto;
  color: white;
  display: flex;
  border: 0;
  padding: 2px;
  font-weight: bold;
  font-size: large;
  background: #7f3616 none;
  box-shadow: none;
  border-radius: 6px;
}
.submit-button:focus {
  background: #7f3616 none;
  outline: none;
  border: none;
}
.submit-button:hover {
  background: #7f3616 none;
  border: none;
  box-shadow: none;
  outline: none;
}
.thank-you-text {
  color: white;
  margin: 15px 20px;
  font-style: italic;
  font-weight: bold;
  width: calc(100% - 20px);
}
</style>
